<template>
  <div :style="{height:height,width:width}" ref="chart"></div>
</template>
<script>
import {debounce} from 'lodash'

const echarts = require("echarts");
export default {
  components: {},
  props: {
    chartData: {
      type: Object,
      default: {
        xAxisData: [],
        seriesData: []
      }
    },
    legendData: {
      type: Array,
      default: []
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
  },
  data() {
    return {
      color: [{
        color: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              {offset: 0, color: 'rgba(35,142,255,0.7)'},
              {offset: 1, color: 'rgba(35,142,255,0.1)'}
            ]
        ),
        borderColor: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              {offset: 0, color: 'rgba(48,181,255,1)'},
              {offset: 1, color: 'rgba(48,181,255,0.2)'}
            ]
        ),//边框颜色
      }, {
        color: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              {offset: 0, color: 'rgba(35,242,255,0.7)'},
              {offset: 1, color: 'rgba(35,242,255,0.1)'}
            ]
        ),
        borderColor: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              {offset: 0, color: 'rgba(44,233,255,1)'},
              {offset: 1, color: 'rgba(44,233,255,0.2)'}
            ]
        )
      }, {
        color: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              {offset: 0, color: 'rgba(0,255,126,0.7)'},
              {offset: 1, color: 'rgba(0,255,126,0.1)'}
            ]
        ),
        borderColor: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              {offset: 0, color: 'rgba(0,255,126,1)'},
              {offset: 1, color: 'rgba(0,255,126,0.2)'}
            ]
        )
      }, {
        color: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              {offset: 0, color: 'rgba(236,254,36,0.7)'},
              {offset: 1, color: 'rgba(236,254,36,0.1)'}
            ]
        ),
        borderColor: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              {offset: 0, color: 'rgba(236,254,36,1)'},
              {offset: 1, color: 'rgba(236,254,36,0.2)'}
            ]
        )
      }, {
        color: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              {offset: 0, color: 'rgba(116,70,256,0.7)'},
              {offset: 1, color: 'rgba(116,70,256,0.1)'}
            ]
        ),
        borderColor: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              {offset: 0, color: 'rgba(116,70,256,1)'},
              {offset: 1, color: 'rgba(116,70,256,0.2)'}
            ]
        )
      }],
    };
  },
  computed: {},
  watch: {
    chartData: {
      handler(val, olVal) {
        //需要在这从新执行一次
        if (val) {
          this.initCharts();
        }
      },
      deep: true
    }
  },
  mounted() {
    this.initCharts();
    this.resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.resizeHandler);
  },
  methods: {
    initCharts() {
      let _this = this;
      this.chart = echarts.init(this.$refs.chart);
      let arr = _this.chartData.seriesData || [];
      let seriesData = []
      arr.forEach((item, i) => {
        seriesData.push({
          name: item.name,
          type: 'bar',
          barGap: "50%",
          barMaxWidth: arr.length>=5 ? "8" : "15" ,
          itemStyle: {
            normal: {
              color: _this.color[i].color,
              borderColor: _this.color[i].borderColor,//边框颜色
            }
          },
          data: item.data
        })
      });
      // 绘制图表
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          backgroundColor: "#09214d",
          borderWidth: 1,
          borderColor: "#7ccaff",
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(35,136,196,0.1)"
            }
          },
        },
        legend: {
          top: 10,
          right: "4%",
          itemGap: 40,
          icon: "circle",
          data: _this.legendData,
          textStyle: {
            fontSize: 14,
            color: "#5bd5f7"
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          splitLine: {show: false},
          axisLine: {
            lineStyle: {
              color: "rgba(102,163,255,0.4)",
            },
          },
          axisLabel: {
            textStyle: {
              fontSize: 14,
              color: "rgba(91,213,247,0.6)",
            },
          },
          axisTick: {
            show: false
          },
          data: _this.chartData.xAxisData
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: "rgba(102,163,255,0.2)",
              type: "solid",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(102,163,255,0.4)",
            },
          },
          axisLabel: {
            textStyle: {
              fontSize: 14,
              color: "rgba(91,213,247,0.6)",
            },
          },
          axisTick: {
            show: false
          }
        },
        series: seriesData
      })
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
};
</script>